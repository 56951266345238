<script setup>
  import commodityItem from '@/views/index/compontent/commodity-item'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    loading: false,
    initLoading: false,
    collectList: []
  })

  // 获取喜欢列表
  const getCollectProduct = () => {
    if (!userStore.userInfo.userId) return
    data.loading = true
    data.initLoading = false
    let params = {
      'collected.equals': true,
      'logicalDeleted.equals': false,
      'userId.equals': userStore.userInfo.userId,
      sort: 'id,desc',
      size: 1000
    }
    api.product
      .getGeneralProduct(params)
      .then((res) => {
        data.collectList = res
        console.log(data.collectList, 'data.collectList ')
      })
      .finally((error) => {
        data.loading = false
        data.initLoading = true
      })
  }

  // 取消某个商品喜欢
  const cancelCollection = (commodity) => {
    console.log('取消喜欢', commodity)
    data.loading = true
    const params = {
      id: commodity.id,
      collected: false,
      logicalDeleted: false,
      praised: false,
      product: commodity.product,
      userId: userStore.userInfo.userId
    }
    // console.log(data)
    api.product.productCollectPraises(params, 'put').then((res) => {
      getCollectProduct()
    })
  }

  onMounted(() => {
    getCollectProduct()
  })
</script>

<template>
  <div class="content" v-loading="data.loading">
    <div class="pro_main_breadcrumb rowSC">
      <span>{{ $t('Home') }}</span>
      <img class="img-ico" src="~img/down_icon.svg" />
      <b>{{ $t('My Favorites') }}</b>
    </div>
    <div class="content-box">
      <div class="user_index_card" v-if="data.collectList.length > 0">
        <div class="user_card_body">
          <div v-for="item of data.collectList" :key="item.id" class="item">
            <commodity-item :item="item.product" :commodity="item" @cancelCollection="cancelCollection" />
          </div>
        </div>
      </div>
      <div class="no_order rowCC" v-else-if="data.initLoading">
        <!-- <el-empty image="https://obs.pricoo.pk/556d1e2f.png" image-size="469" default="" /> -->
        <JLImg src="https://obs.pricoo.pk/556d1e2f.png" class="no-box"></JLImg>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .content {
    .content-box {
      min-height: 620px;
    }
  }

  .pro_main_breadcrumb {
    width: 100%;
    padding: 12px 0;
    font-size: 13px;
    line-height: 30px;
    .img-ico {
      margin: 9px;
      width: 12px;
      height: 12px;
      transform: rotate(270deg);
    }
  }

  .user_index_card {
    width: 100%;
    background: #fff;
    margin-bottom: 15px;
    .user_card_body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px 10px;
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      .item {
        width: calc(100% / 4 - 10px);
        &:nth-child(4n) {
          margin-right: 0;
        }
        /* 使最后一个元素的边距填满剩余空间 */
        &:last-child {
          margin-right: auto;
        }
      }
    }
  }

  .no_order {
    min-height: 620px;
    .no-box {
      width: 469px;
    }
  }
</style>
