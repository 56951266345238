const transformString = (str) => {
  if (!str) return
  // 将下划线替换为空格，并将字符串转换为小写
  let modifiedStr = str.replace(/_/g, ' ').toLowerCase()
  // 将每个单词的首字母大写
  modifiedStr = modifiedStr.replace(/\b\w/g, function (char) {
    return char.toUpperCase()
  })
  if (modifiedStr === 'Cod') {
    modifiedStr = 'COD'
  }
  return modifiedStr
}

let paymentList = [
  { type: 'COD', typeName: 'cash-on-delivery', name: '货到付款', url: require('@/assets/images/cash-on-delivery-icon.svg'), btnName: 'Cash On Delivery', width: 28 },
  { type: 'EASY_PAISA', typeName: 'easy-paisa', name: 'EasyPaisa', url: require('@/assets/images/easy-paisa-icon.svg'), btnName: 'EasyPaisa' },
  { type: 'CARD', typeName: 'card', name: '卡支付', url: require('@/assets/images/card-icon.svg'), btnName: 'Credit/Debit Card' },
  // { type: 'BANK_TRANSFER', typeName: 'bank-transfer', name: '转账', url: require('@/assets/images/bank-transfer-icon.png'), btnName: 'Bank Transfer' },
  { type: 'JAZZ_CASH', typeName: 'jazzcash', name: 'jazzcash', url: 'https://obs.pricoo.pk/75a4a6f2.png', btnName: 'JazzCash', width: 28 }
]

let paymentIconMap = {
  "COD": {
    src: require('@/assets/images/cash-on-delivery-icon.svg'),
    width: '23px'
  },
  "EASY_PAISA": {
    src: require('@/assets/images/easy-paisa-icon.svg'),
    width: '18px'
  },
  "CARD": {
    src: require('@/assets/images/card-icon.svg'),
    width: '18px'
  },
  "JAZZ_CASH": {
    src: 'https://obs.pricoo.pk/75a4a6f2.png',
    width: '23px'
  }
}

export { transformString, paymentIconMap }
