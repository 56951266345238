<script setup>
  import zindagiPopup from '@/views/activity/zindagi/compontent/zindagi-popup.vue'
  import Pagination from '@/components/Pagination'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, nextTick } from 'vue'
  import JLTools from '@/util/JLTools'
  import { strTitleCase } from '@/util/func'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['isInitializedToggle', 'skipDetails'])
  const zindagiRef = ref()

  const tableData = [
    {
      ac_quantity: 1,
      created_at: '2024-04-26T07:04:43.000000Z',
      id: 27,
      id_card_issuance_date: '2022-04-20',
      id_card_no: '1234567890123',
      installation_complete_date: '2024-04-01',
      installation_request_number: '["ABC1234567890123"]',
      invoice_picture: '["invoice.jpg"]',
      name: 'John Doe',
      phone_no: '03001234567',
      purchase_date: '2022-04-15',
      status: 'pending',
      uid: '11522d65-d36b-4ab0-8d08-1964c362531c',
      updated_at: '2024-04-26T07:04:43.000000Z',
      user_id: 58,
      warranty_card_picture: '["warranty_card.jpg"]'
    },
    {
      ac_quantity: 1,
      created_at: '2024-04-26T07:04:43.000000Z',
      id: 27,
      id_card_issuance_date: '2022-04-20',
      id_card_no: '1234567890123',
      installation_complete_date: '2024-04-01',
      installation_request_number: '["ABC1234567890123"]',
      invoice_picture: '["invoice.jpg"]',
      name: 'John Doe',
      phone_no: '03001234567',
      purchase_date: '2022-04-15',
      status: 'Rejected',
      uid: '11522d65-d36b-4ab0-8d08-1964c362531c',
      updated_at: '2024-04-26T07:04:43.000000Z',
      user_id: 58,
      warranty_card_picture: '["warranty_card.jpg"]'
    }
  ]

  const data = reactive({
    tableData: [],
    curItem: {},
    type: 'add',
    loading: false
  })

  const pagingParms = reactive({
    page: 1,
    pageSize: 10,
    total: 0
  })

  const getStatusClass = (status) => {
    if (!status) return
    switch (status) {
      case 'pending':
        return 'pending-status'
      case 'approved':
        return 'approved-status'
      case 'rejected':
        return 'rejected-status3'
      default:
        return 'pending-status'
    }
  }

  // 获取返现列表
  const getCashbackList = () => {
    console.log(pagingParms, 'pagingParms')
    if (JLTools.isNull(userStore.userInfo.userId)) return
    $emit('isInitializedToggle', false)
    data.loading = true
    let parms = {
      user_id: userStore.userInfo.userId,
      per_page: pagingParms.pageSize,
      page: pagingParms.page,
      sorting: 'DESC'
    }
    api.activity
      .getCashbackList(parms)
      .then((res) => {
        // console.log(res, 'getCashbackList')
        data.tableData = res.data.data
        pagingParms.total = res.data.total
        // console.log(res.data, 'res.data.total')
        $emit('isInitializedToggle', true)
        data.loading = false
      })
      .catch(() => {
        $emit('isInitializedToggle', true)
        data.loading = false
      })
  }

  // 分页处理
  const pageList = (val) => {
    pagingParms.page = val
    getCashbackList()
  }

  // 增加返现=>弹出返现弹窗
  const addRewards = (type, obj) => {
    data.type = type || 'add'
    data.curItem = {}
    console.log('弹出返现弹窗')
    nextTick()
    // 弹出弹出
    if (type === 'edit') {
      data.curItem = obj
    } else if (data.tableData.length > 0) {
      data.curItem = {
        name: data.tableData[0].name,
        phoneNo: data.tableData[0].phone_no,
        idCardNo: data.tableData[0].id_card_no,
        idCardIssuanceDate: data.tableData[0].id_card_issuance_date
      }
    }
    console.log('curItem', data.type, data.curItem)
    zindagiRef.value.zindagiPopupShow({
      type: data.type,
      curItem: data.curItem
    })
  }

  const skipDetails = (item) => {
    console.log('跳转详情页')
    $emit('skipDetails', item)
  }

  onMounted(() => {
    getCashbackList()
  })

  defineExpose({
    addRewards
  })
</script>

<template>
  <div>
    <el-table :data="data.tableData" class="table-box" border stripe style="width: 100%" v-loading="data.loading" :header-cell-style="{ background: '#F5F5F5', color: '#000' }">
      <el-table-column prop="id" label="Request ID" align="center" />
      <el-table-column prop="updated_at" label="Request Date" width="160" align="center">
        <template #default="scope">
          <div>{{ JLTools.formatDate(scope.row.updated_at) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="campaign" label="Campaign" width="180" align="center"></el-table-column>
      <el-table-column label="Status" width="200" align="center">
        <template #default="scope">
          <div class="rowCC">
            <div class="rowSC status-box">
              <div class="status" :class="getStatusClass(scope.row.log_status)">{{ strTitleCase(scope.row.log_status) }}</div>
              <el-tooltip class="box-item" effect="light" placement="top" v-if="scope.row.log_status === 'rejected' && scope.row.rejection_reason">
                <template #content>
                  <div class="tooltip-title">Rejected Reason</div>
                  <el-divider class="divider" />
                  <div class="text-warp">
                    {{
                      JSON.parse(scope.row.rejection_reason)?.rejection_reason === 'Other Reasons'
                        ? JSON.parse(scope.row.rejection_reason)?.remarks
                        : JSON.parse(scope.row.rejection_reason)?.rejection_reason
                    }}
                  </div>
                </template>
                <div class="tooltip-text"></div>
              </el-tooltip>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Operation" width="150" align="center">
        <template #default="scope">
          <div class="btn mt10" v-if="scope.row.log_status === 'rejected' && scope.row.edit_active === 1" @click="addRewards('edit', scope.row)">edit</div>
          <div v-else @click="skipDetails(scope.row)" class="details-text btn">View Details</div>
        </template>
      </el-table-column>
    </el-table>

    <div class="rowBC mt20" v-if="pagingParms.total > 0">
      <div>
        {{ $t('About') }} <b>{{ pagingParms.total }}</b> {{ $t('results were found') }}
      </div>
      <div class="pagination-box">
        <Pagination :config="{ total: pagingParms.total, pageSize: pagingParms.pageSize, currentPage: pagingParms.page }" @currentPage="pageList"></Pagination>
      </div>
    </div>

    <zindagiPopup ref="zindagiRef" @informSuc="getCashbackList"></zindagiPopup>
  </div>
</template>

<style lang="less" scoped>
  .tab-header-box {
    .pro-main-breadcrumb {
      width: fit-content;
      font-size: 13px;
      line-height: 30px;

      img {
        margin: 9px;
        width: 12px;
        height: 12px;
        transform: rotate(270deg);
      }
    }
  }
  .divider {
    margin: 12px 0;
  }

  .table-box {
    color: #000;
    .status-box {
      width: 130px;
      margin-left: 30px;
      .status {
        width: 100px;
        padding: 6px 0px;
        margin: 10px 0;
        border-radius: 20px;
        color: #fff;
      }
      .tooltip-text {
        width: 20px;
        height: 20px;
        margin-left: 5px;
        background: url('https://obs.pricoo.pk/f586be78.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .btn {
      cursor: pointer;
    }

    .details-text {
      text-decoration: underline;
      cursor: pointer;
    }
    .pending-status {
      background: rgba(255, 165, 7, 1);
    }
    .approved-status {
      background: rgba(33, 181, 0, 1);
    }
    .rejected-status3 {
      background: rgba(246, 63, 66, 1);
    }
  }

  .pagination-box {
    display: flex;
    flex-direction: row-reverse;
  }

  .text-warp {
    max-width: 200px;
    white-space: wrap;
  }
  .tooltip-title {
    font-weight: 500;
  }
</style>
